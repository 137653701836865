exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-leasing-wynajem-[single-vehicle]-jsx": () => import("./../../../src/pages/leasing-wynajem/[single-vehicle].jsx" /* webpackChunkName: "component---src-pages-leasing-wynajem-[single-vehicle]-jsx" */),
  "component---src-pages-leasing-wynajem-jsx": () => import("./../../../src/pages/leasing-wynajem.jsx" /* webpackChunkName: "component---src-pages-leasing-wynajem-jsx" */),
  "component---src-pages-najczesciej-zadawane-pytania-jsx": () => import("./../../../src/pages/najczesciej-zadawane-pytania.jsx" /* webpackChunkName: "component---src-pages-najczesciej-zadawane-pytania-jsx" */),
  "component---src-pages-o-nas-jsx": () => import("./../../../src/pages/o-nas.jsx" /* webpackChunkName: "component---src-pages-o-nas-jsx" */),
  "component---src-pages-oblicz-rate-jsx": () => import("./../../../src/pages/oblicz-rate.jsx" /* webpackChunkName: "component---src-pages-oblicz-rate-jsx" */),
  "component---src-pages-oferta-auto-dla-osoby-prywatnej-jsx": () => import("./../../../src/pages/oferta/auto-dla-osoby-prywatnej.jsx" /* webpackChunkName: "component---src-pages-oferta-auto-dla-osoby-prywatnej-jsx" */),
  "component---src-pages-oferta-kredyty-samochodowe-dla-firm-i-osob-fizycznych-prywatnych-jsx": () => import("./../../../src/pages/oferta/kredyty-samochodowe-dla-firm-i-osob-fizycznych-prywatnych.jsx" /* webpackChunkName: "component---src-pages-oferta-kredyty-samochodowe-dla-firm-i-osob-fizycznych-prywatnych-jsx" */),
  "component---src-pages-oferta-leasing-bez-bik-big-i-krd-jsx": () => import("./../../../src/pages/oferta/leasing-bez-bik-big-i-krd.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-bez-bik-big-i-krd-jsx" */),
  "component---src-pages-oferta-leasing-finansowy-pozyczka-leasingowa-jsx": () => import("./../../../src/pages/oferta/leasing-finansowy-pozyczka-leasingowa.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-finansowy-pozyczka-leasingowa-jsx" */),
  "component---src-pages-oferta-leasing-maszyn-budowlanych-jsx": () => import("./../../../src/pages/oferta/leasing-maszyn-budowlanych.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-maszyn-budowlanych-jsx" */),
  "component---src-pages-oferta-leasing-maszyn-i-urzadzen-cnc-it-medycznych-lesnych-jsx": () => import("./../../../src/pages/oferta/leasing-maszyn-i-urzadzen-cnc-it-medycznych-lesnych.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-maszyn-i-urzadzen-cnc-it-medycznych-lesnych-jsx" */),
  "component---src-pages-oferta-leasing-na-start-jsx": () => import("./../../../src/pages/oferta/leasing-na-start.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-na-start-jsx" */),
  "component---src-pages-oferta-leasing-paneli-fotowoltaicznych-fotowoltaiki-jsx": () => import("./../../../src/pages/oferta/leasing-paneli-fotowoltaicznych-fotowoltaiki.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-paneli-fotowoltaicznych-fotowoltaiki-jsx" */),
  "component---src-pages-oferta-leasing-samochodow-nowych-i-uzywanych-jsx": () => import("./../../../src/pages/oferta/leasing-samochodow-nowych-i-uzywanych.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-samochodow-nowych-i-uzywanych-jsx" */),
  "component---src-pages-oferta-leasing-samochodow-osobowych-i-dostawczych-jsx": () => import("./../../../src/pages/oferta/leasing-samochodow-osobowych-i-dostawczych.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-samochodow-osobowych-i-dostawczych-jsx" */),
  "component---src-pages-oferta-leasing-sprzetu-it-jsx": () => import("./../../../src/pages/oferta/leasing-sprzetu-it.jsx" /* webpackChunkName: "component---src-pages-oferta-leasing-sprzetu-it-jsx" */),
  "component---src-pages-oferta-ubezpieczenia-oc-ac-gap-jsx": () => import("./../../../src/pages/oferta/ubezpieczenia-oc-ac-gap.jsx" /* webpackChunkName: "component---src-pages-oferta-ubezpieczenia-oc-ac-gap-jsx" */),
  "component---src-pages-oferta-wynajem-dlugoterminowy-jsx": () => import("./../../../src/pages/oferta/wynajem-dlugoterminowy.jsx" /* webpackChunkName: "component---src-pages-oferta-wynajem-dlugoterminowy-jsx" */)
}

